import { useState, useEffect,useContext } from 'react';
import HighchartsTemplate from './HighchartTemplate';
import axios from 'axios';
import _ from 'lodash';
const axiosInstance = axios.create()
axiosInstance.defaults.headers.common['X-Api-Key'] = 'b2jqI6b9nDMBUKfPBBYkhW8tI8Q5DnqpX0biA2X6'

export default function CsgServiceCount(props){
	const [csg_service_count,setCount] = useState({})

	useEffect(()=>{
		axiosInstance.get(`/sales/graphs/1/liens/breakdown/json`)
			.then(payload=>{
				setCount(payload.data)
			});
	},[])


	if(_.isEmpty(csg_service_count)){
		return(
			<span className="far fa-spinner fa-spin fa-5x" />
		)
	}

	let series_data = [];
	let drilldown_data = [];
	let drilldown_series = [];
	let total_count = 0;
	let percent;
	_.forEach(csg_service_count,(val,key)=>{
		drilldown_data = [];
		percent = val.total/total_count * 100;
		_.forEach(val,(value)=>{
			if(value.cdescript) {
				drilldown_data.push([value.cdescript, _.toNumber(value.count)]);
			}
		});
		drilldown_series.push({id:key,data:drilldown_data,format: '{point.id}'});
		series_data.push({name:key,y:val.total,drilldown:key,percent:percent});
		total_count += val.total;
	});

	const config = {
		chart: {
			type: 'pie'
		},
		title: {
			text: "CSG Service Count (Month-to-Date)"
		},
		subtitle:{
			text:'Click to view breakdown'
		},
		xAxis: {
			categories: '',
			tickmarkPlacement: 'on',
			title: {
				enabled: false
			}
		},
		plotOptions: {
			pie: {
				cursor: 'pointer',
				dataLabels: {
					enabled: true,
					format: '{point.name} {point.percent:.1f}%'
				},
				showInLegend: true
			}
		},
		legend:{
			enabled:false
		},
		credits: false,
		tooltip: {
			pointFormat: "<span style='color: {point.color}'>{point.name}</span>: <b>{point.y:,.0f}</b><br/>"
		},
		series: [{
			name:'Services',
			colorByPoint: true,
			data:series_data
		}],
		drilldown:{
			series: drilldown_series
		},
		responsive: {}
	};
	return(
		<HighchartsTemplate id={props.id} config={config}/>
	)
}
