import React, { Component } from 'react';
import axios from '../axios';
import NoticeGraph from './widgets/NoticeIndexGraph';
import WorkloadAssignment from './widgets/WorkloadAssignmentGraph';
import CsgServiceCount from './widgets/CsgServiceCount';
import UccCurrentOpen from './widgets/UccCurrentOpen';
import UccActivity from './widgets/UccActivity';
import YtdSales from './widgets/YtdSales';
import CEDImportGraph from "./widgets/CEDImportGraph";
import {PortalContext} from "../GlobalState";

export default class Homepage extends Component{
	static contextType = PortalContext
	constructor(props){
		super(props);

		this.state = {
			isLoading:true,
			edit:false,
			settings:{},
			graphs:{},
			max_order_by:{},
			socketMsg:'',
			portalMsg:''
		}
	}
	componentDidMount(){
		const socket = this.context.socket

		socket?.on && socket?.on('message',socketMsg=>{
			this.setState({socketMsg})
		})

		socket?.on && socket?.on('portal',portalMsg=>{
			this.setState({portalMsg})
		})
		axios.get(`/ncs/employee/1/homepage/${this.context?.employee?.emp_id}/json`)
			.then(payload=>{
				this.updateSettings(payload.data);
			})
	}
	updateSettings(update){
		this.setState({
			settings: update.settings,
			graphs: update.graphs,
			max_order_by: update.max_order_by,
			isLoading:false
		})
	}
	changeGraph(id,val) {
		axios.patch(`/ncs/employee/1/homepage/graph/${id}/${val}json`)
			.then(update=>{
				this.updateSettings(update.data)
			})
			.then(()=>{
				let resizeEvent = window?.document?.createEvent('UIEvents');
				resizeEvent.initUIEvent('resize', true, false, window, 0);
				window?.dispatchEvent(resizeEvent);
			});
	}
	changeOptionSize(id,size){
		if(size > 12){
			size = 12;
		}
		else if(size < 4){
			size = 4;
		}

		axios.patch(`/ncs/employee/1/homepage/col_size/${id}/${size}/json`)
			.then(update=>{
				this.updateSettings(update.data)
			})
			.then(()=>{
				let resizeEvent = window?.document?.createEvent('UIEvents');
				resizeEvent.initUIEvent('resize', true, false, window, 0);
				window?.dispatchEvent(resizeEvent);
			});
	}
	changeOrderBy(id,old_order_by,new_order_by){
		axios.patch(`/ncs/employee/1/homepage/order_by/${id}/${old_order_by}/${new_order_by}/${this.context?.employee?.emp_id}/json`)
			.then(settings => {
				this.updateSettings(settings.data)
			});
	}
	deleteGraph(id){
		axios.patch(`/ncs/employee/1/homepage/delete/${id}/${this.context?.employee?.emp_id}/json`)
			.then(settings => {
				this.updateSettings(settings.data)
			});
	}
	addGraph(){
		axios.post(`/ncs/employee/1/homepage/add_graph/${this.context?.employee?.emp_id}/json`)
			.then(settings=>{
				this.updateSettings(settings.data)
			});
	}

	render(){
		const { settings,graphs,isLoading,max_order_by } = this.state;
		//console.log('homepage state',this.state);

		let display = [];
		let loading;
		let display_message;
		if(isLoading){
			loading = <span className="far fa-spin fa-spinner fa-5x"/>
		}
		else {
			display_message = <div className={"col-12" + (this.state.edit ? ' hidden' : '')}>
				<h4>
					<div className='alert alert-info float-right text-right fade in out'>
						<span className='far fa-info-circle'/> You can add widgets to this page by clicking the edit
						button <span className='far fa-hand-point-up'/>
					</div>
				</h4>
			</div>;
		}
		let graph_options = [];
		if(graphs.length > 0){
			graphs.map(graph=>{
				graph_options.push(<option key={graph.id} value={graph.id}>{graph.graph}</option>)
			})
		}
		if(!settings.result && settings.length > 0){
			let graph;
			let count = 1;
			settings.map((row)=>{
				let graph_to_add;
				graph = row.graph_id;
				let graph_ref = row.graph+count;
				switch (graph) {
					case 1:
						graph_to_add = <NoticeGraph id={graph_ref}/>;
						break;
					case 2:
						graph_to_add = <WorkloadAssignment id={graph_ref}/>;
						break;
					case 3:
						graph_to_add = <CsgServiceCount id={graph_ref}/>;
						break;
					case 4:
						graph_to_add = <UccCurrentOpen/>;
						break;
					case 5:
						graph_to_add = <UccActivity/>;
						break;
					case 6:
						graph_to_add = <YtdSales/>;
						break;
					case 7:
						graph_to_add = <CEDImportGraph id={graph_ref}/>;
						break;
					default:
						break;
				}
				if(graph_to_add) {
					count++;
				}
				if(!graph_to_add){
					graph_to_add = <div className={"col-12"+ (this.state.edit ? '' : ' hidden')}>
						<h4>
							<div className='alert alert-info fade in out' style={{marginBottom:339}}>
								<span className='far fa-hand-point-up'/> Select a graph
							</div>
						</h4>
					</div>
				}

				display.push(
					<div key={row.id} className={'col-12 col-sm-'+(row.col_size)}>
						<div className={"row homepage-widget"+ (this.state.edit?' edit_homepage_option':'')}>
							<div className="col-12">
								<div className="row" hidden={!this.state.edit}>
									<div className="col-12">
										<div className="form-inline">
											<div className="form-group">
												<div className="btn-group btn-group-sm">
													<button className="btn btn-default" disabled={row.col_size == 4} onClick={()=>{this.changeOptionSize(row.id,row.col_size-1)}}><span className="far fa-minus"/></button>
													<button className="btn btn-default" disabled={row.order_by == 1} onClick={()=>{this.changeOrderBy(row.id,row.order_by,row.order_by-1)}}><span className="far fa-arrow-left"/></button>
													<button className="btn btn-default" disabled={row.order_by == max_order_by} onClick={()=>{this.changeOrderBy(row.id,row.order_by,row.order_by+1)}}><span className="far fa-arrow-right"/></button>
													<button className="btn btn-default" disabled={row.col_size == 12} onClick={()=>{this.changeOptionSize(row.id,row.col_size+1)}}><span className="far fa-plus"/></button>
												</div>
											</div>
											&nbsp;
											&nbsp;
											<div className="form-group">
												<select className="form-control" value={row.graph_id?row.graph_id:''} onChange={(e)=>{this.changeGraph(row.id,e.target.value)}}>
													<option>--None--</option>
													{graph_options}
												</select>
											</div>
										</div>
										<span className="far fa-times fa-2x pointer" onClick={()=>{this.deleteGraph(row.id)}} style={{position:'absolute',top:2,right:5}}/>
									</div>
								</div>
								<div style={{padding:'15px 5px'}}>
									{graph_to_add}
								</div>
							</div>
						</div>
					</div>
				);
			});

			if(count > 1) {
				display_message = '';
			}
		}
		let edit_save_button;
		if(this.state.edit){
			edit_save_button = <button onClick={()=>{this.setState({edit:!this.state.edit})}} className="btn btn-primary"><span className="far fa-check"/> Done</button>
		}
		else{
			edit_save_button = <button onClick={()=>{this.setState({edit:!this.state.edit})}} className="btn btn-default"><span className="far fa-cog"/> Edit</button>;
		}
		let add_graph = <div className={'border-3 pointer'+(this.state.edit?'':' hidden')} style={{height:440,marginTop:20,display:'table',width:'100%'}} onClick={()=>{this.addGraph()}}>
			<div className="text-center" style={{display:'table-cell',verticalAlign:'middle'}}>
				<span className="fas fa-plus fa-2x" style={{color:'gray'}}/> <span style={{fontSize:24,color:'gray'}}>Add Graph</span>
			</div>
		</div>;

		return(
			<div className="mt-2">
				{this.state.portalMsg.length > 0 && <h2>{this.state.portalMsg}</h2>}
				{this.state.socketMsg.length > 0 && <h2>{this.state.socketMsg}</h2>}
				<div className="row">
					<div className="col-12">
						<div className="float-right" style={{marginRight:15}}>
							{edit_save_button}
						</div>
					</div>
				</div>
				{loading}
				<div className="row">
					{display_message}
					{display}
					<div className="col-xs-4">
						{add_graph}
					</div>
				</div>
			</div>
		)
	}
}
