import React, { useState, useEffect,useContext } from 'react';
import axios from '../../axios';
import {PortalContext} from "../../GlobalState";

export default function UccActivity(props){
	const [isLoading,setLoading] = useState(false)
	const [lastActivity,setActivity] = useState({})
	const {employee} = useContext(PortalContext)

	useEffect(()=>{
		setLoading(true)
		axios.get(`/portal/ucc/1/last_activity/${employee.EmpID}/json`)
			.then(activity=>{
				setLoading(false)
				setActivity(activity.data)
			})
	},[])

	function createTable(){
		if(lastActivity.length > 0) {
			return lastActivity.map((service) => {
				return <tr key={service.id}>
					<td><strong><a href={"/ucc/" + service.ucc_no}>{service.ucc_no}</a></strong></td>
					<td>{service.SERV_DESC}</td>
					<td>{service.stage_name}</td>
				</tr>
			})
		}
		else{
			let i;
			let empty_code = [];
			for(i = 0;i < 5; i++){
				empty_code.push(<tr key={i}>
						<td><span className="gray-block" style={{width:90}}/></td>
						<td><span className="gray-block" style={{width:150}}/></td>
						<td><span className="gray-block" style={{width:60}}/></td>
					</tr>
				)
			}
			return empty_code;
		}
	}

		// console.log('last activity',this.state);
		if(isLoading){
			return <span className="far fa-spinner fa-spin"/>;
		}

		return(
			<div style={{height:400}}>
				<h3 style={{margin:0}}>Last Activity</h3>
				<div className="table-responsive" style={{overflowY:'auto',maxHeight:364,marginTop:10}}>
					<table className="table">
						<thead>
							<tr>
								<th>UCC #</th>
								<th>Service</th>
								<th>Completed</th>
							</tr>
						</thead>
						<tbody>
							{createTable()}
						</tbody>
					</table>
				</div>
			</div>
		)

}
