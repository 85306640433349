import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official'
if (typeof Highcharts === 'object') {
	HighchartsExporting(Highcharts)
}

export default function HighchartsTemplate(props){
	return(
		<HighchartsReact
			highcharts={Highcharts}
			options={props.config}
		/>
	)
}