import {useEffect,useState,useContext} from 'react';
import _ from 'lodash';
import HighchartsTemplate from './HighchartTemplate';
import axios from '../../axios';
import {PortalContext} from "../../GlobalState";

export default function WorkloadAssignmentGraph(props){
	const [workload,setWorkload] = useState({})
	const {employee} = useContext(PortalContext)
	useEffect(()=>{
		let today = new Date();
		let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
		today.setDate(today.getDate() + 1);
		today = formatDate(today);
		firstDay = formatDate(firstDay);
		const admin = parseInt(employee.admin)
		if (admin <= 5 && admin !== 0 && admin !== null) {
			axios.get(`/portal/workload/1/assignments/${firstDay}/${today}/all/json`)
				.then(payload=>{
					setWorkload(payload.data)
				});
		}
		else {
			axios.get(`/portal/workload/1/assignments/${firstDay}/${today}/${employee.EmpID}/json`)
				.then(payload=>{
					setWorkload(payload.data)
				});
		}
	},[])

	const formatDate = (date) => {
		let d = new Date(date),
			month = '' + (d.getMonth() + 1),
			day = '' + d.getDate(),
			year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [year, month, day].join('-');
	}

	if(_.isEmpty(workload)){
		return(
			<span className="far fa-spinner fa-spin fa-5x" />
		)
	}
	if(workload['no_data']){
		return(
			<div className="col-12">
				<h4>
					<div className='alert alert-dismissible alert-info pull-left fade in out'>
						<button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
						Workload Assignments Graph did not return any results for this date range
					</div>
				</h4>
			</div>
		)
	}
	let series_data = [];
	let total;
	let emp_name;
	let assignments;
	let reviews;
	let drilldown_series = [];
	let drilldown_data;
	_.forEach(workload,(row)=>{
		assignments = _.toInteger(row['assignments']);
		reviews = _.toInteger(row['reviews']);
		total = assignments+reviews;
		emp_name = row['emp_name'];
		series_data.push({name:emp_name,y:total,drilldown:emp_name});
		drilldown_data = [['Assignments',assignments],['Reviews',reviews]];
		drilldown_series.push({name:emp_name,id:emp_name,data:drilldown_data});
	});
	let config = {
		chart: {
			type: 'column'
		},
		title: {
			text: 'Assignments and Reviews'
		},
		subtitle: {
			text: 'Click to view breakdown'
		},
		xAxis: {
			type: 'category'
		},
		yAxis: {
			title: {
				text: 'Total'
			}

		},
		legend: {
			enabled: false
		},
		tooltip:{
			enabled: false
		},
		plotOptions: {
			series: {
				borderWidth: 0,
				dataLabels: {
					enabled: true,
					format: '{point.y}'
				}
			}
		},
		credits: false,
		series: [{
			name: 'Total',
			colorByPoint: true,
			data: series_data
		}],
		drilldown: {
			series: drilldown_series
		}
	};
	return(
		<HighchartsTemplate id={props.id} config={config}/>
	)
}
