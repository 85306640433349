import { useState, useEffect,useContext } from 'react';
import HighchartsTemplate from './HighchartTemplate';
import axios from '../../axios';
import _ from 'lodash';
import dayjs from "dayjs";

export default function NoticeIndexGraph(props){
	const [notice,setNotice] = useState({})
	useEffect(()=>{
		axios.get(`/portal/notice/1/range_years/json`)
			.then(payload=>{
				setNotice(payload.data)
			})
	},[])

	if(_.isEmpty(notice)){
		return(
			<span className="far fa-spinner fa-spin fa-5x" />
		)
	}
	const {this_year,last_year,date_range} = notice;
	let last_array = [];
	let this_array = [];
	let categories_array = [];
	_.forEach(last_year,function(value){
		let recipients = parseInt(value.recipients_sent);
		last_array.push(recipients);
	});
	_.forEach(this_year,function(value){
		let recipients = parseInt(value.recipients_sent);
		this_array.push(recipients);
	});
	const series = [{
		name: 'This Year',
		data: this_array
	}, {
		name: 'Last Year',
		data: last_array
	}];

	_.forEach(date_range,function(value){
		let date = dayjs(value).format('MM/DD');
		categories_array.push(date);
	});
	const config = {
		chart: {
			type: 'column'
		},
		title: {
			text: "Notices Sent Beginning of Month to Today"
		},
		xAxis: {
			categories: categories_array,
			tickmarkPlacement: 'on',
			title: {
				enabled: false
			}
		},
		yAxis: {
			title: {
				text: "# of Recipients Sent"
			}
		},
		credits: false,
		tooltip: {
			animation: true
		},
		series: series
	};
	return(
		<HighchartsTemplate id={props.id} config={config}/>
	)
}
